import api from "@/api";

class ReportService {
  static getReportDeposits(daterange) {
    return api({
      url: "report-deposits",
      params: { daterange }
    });
  }

  static getReportMoneyInDistributor(daterange, received) {
    return api({
      url: "report-money-distributor",
      params: { daterange, received }
    });
  }

  static markReceivedMoney(data) {
    return api({
      url: 'mark-received-money',
      data,
      method: 'post'
    })
  }

  static markAllReceivedMoney(data) {
    return api({
      url: 'mark-all-received-money',
      data,
      method: 'post'
    })
  }

  //Get report invoices
  static getReportInvoices(page, search = {}) {
    let params = "";
    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.rangeDates) {
      params += `&range_dates=${search.rangeDates}`;
    }

    return api.get(`report-invoices?page=${page}${params}`);
  }

  //Get report skip invoices
  static getReportInvoicesSkip(page, search = {}) {
    let params = "";
    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.rangeDates) {
      params += `&range_dates=${search.rangeDates}`;
    }

    if (search.skip) {
      params += `&skip=${search.skip}`;
    }

    return api.get(`report-invoices-skip?page=${page}${params}`);
  }

  //Download excel report invoices
  static downloadExcelReportInvoices(page = 1, search = {}) {
    let params = "";
    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.rangeDates) {
      params += `&range_dates=${search.rangeDates}`;
    }
    return api.get(`download-excel-report-invoices?page=${page}${params}`, {
      responseType: "blob"
    });
  }

  //Get report expenses
  static getReportExpenses(page = 1, search = {}) {
    let params = "";
    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.expenses_year) {
      params += `&expenses_year=${search.expenses_year}`;
    }

    return api.get(`report-expenses?page=${page}${params}`);
  }

  //Get report notes
  static getReportNotes(page, search = {}) {
    let params = "";
    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.rangeDates) {
      params += `&range_dates=${search.rangeDates}`;
    }

    return api.get(`report-notes?page=${page}${params}`);
  }

  //Get report skip notes
  static getReportNotesSkip(page, search = {}) {
    let params = "";
    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.rangeDates) {
      params += `&range_dates=${search.rangeDates}`;
    }

    if (search.skip) {
      params += `&skip=${search.skip}`;
    }

    return api.get(`report-notes-skip?page=${page}${params}`);
  }

  //Get report receptions
  static getReportReceptions(page, search = {}) {
    let params = "";
    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.rangeDates) {
      params += `&range_dates=${search.rangeDates}`;
    }

    return api.get(`report-receptions?page=${page}${params}`);
  }

  //Get report skip receptions
  static getReportReceptionsSkip(page, search = {}) {
    let params = "";
    if (search.term) {
      params += `&search=${search.term}`;
    }

    if (search.options) {
      params += `&options=${search.options}`;
    }

    if (search.rangeDates) {
      params += `&range_dates=${search.rangeDates}`;
    }

    if (search.skip) {
      params += `&skip=${search.skip}`;
    }

    return api.get(`report-receptions-skip?page=${page}${params}`);
  }

  static getAccountState(client_id, daterange, response_type, export_type = '') {
    return api({
      url: "account-state",
      params: { daterange, client_id, response_type, export_type },
      responseType: response_type
    });
  }
}

export default ReportService;
